<template src="./ChangePasswordForm.html"></template>
<style scoped lang="scss" src="./ChangePasswordForm.scss"></style>

<script>
export default {
  name: 'ChangePasswordForm',
  data: function () {
    return {
      isLoading: false,
      data: {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      },
      rules: {
        oldPassword: [
          { required: true, message: '必須項目です。', trigger: 'change' }
        ],
        newPassword: [
          { required: true, message: '必須項目です。', trigger: 'change' }
        ],
        confirmNewPassword: [
          { required: true, message: '必須項目です。', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    async onSubmit() {
      const valid = await this.$refs.form.validate()
      if (!valid) return console.log('Form is inValid')

      this.isLoading = true

      // TODO


      return console.log('Form is valid')
    },
  }
}
</script>
