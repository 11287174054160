<template src="./ChangePassword.html"></template>
<style scoped lang="scss" src="./ChangePassword.scss"></style>

<script>
import ChangePasswordForm from "../../../../components/form/change-password/ChangePasswordForm";

export default {
  name: 'ChangePassword',
  components: { ChangePasswordForm }
}
</script>